"use client";
import { allDarkColors, allLightColors } from "@/providers/theme/theme-data";
import { useThemeArea } from "@/providers/theme/theme-provider";
import { Button } from "@lifizy/ui";
import Link from "next/link";
import React from "react";
import BlockType from "../../model/block-type";
import Carousel from "../carousel";
import Editable from "../edit/editable";
import BackgroundImage from "../image/BackgroundImages";

interface BlockProps {
  blockData: BlockType;
  effect?: string;
  editable: boolean;
}

const Block: React.FC<BlockProps> = ({ blockData, editable }) => {
  const themeArea = useThemeArea("web");
  if (!themeArea) return <></>;
  const hasTransparency = themeArea.theme.headerOptions.includes("transparency");
  const hasBackgroundImages = themeArea.theme.headerOptions.includes("backgroundImages");
  const hasMulticolor = themeArea.theme.menuOptions.includes("multicolor");
  const dark = themeArea.theme.modes.includes("dark");
  const full = themeArea.theme.menuOptions.includes("full");
  const colors = dark ? allDarkColors : allLightColors;
  const { title, subTitle, surTitle, buttons } = blockData;
  return (
    <div
      className={`relative flex flex-col-reverse pt-16 lg:pt-0 lg:flex-col lg:pb-20 lg-mb-10 ${hasBackgroundImages ? "text-text" : "pb-10"} 
      ${hasMulticolor ? colors[5] : ""}`}>
      <div
        className={`inset-y-0 top-0 right-0 z-0 w-full max-w-xl mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0
      ${full ? "" : "px-8"}`}>
        {!hasBackgroundImages ? (
          <>
            {/* <svg
              className="absolute left-0 hidden h-full text-primary transform -translate-x-1/2 lg:block"
              viewBox="0 0 100 100"
              fill="currentColor"
              preserveAspectRatio="none slice">
              <path d="M50 0H100L50 100H0L50 0Z" />
            </svg> */}
            <Carousel />
          </>
        ) : (
          <BackgroundImage dark={dark} />
        )}
      </div>
      <div
        className={`${hasTransparency ? "pt-10" : ""} relative flex flex-col items-start w-full max-w-xl px-8 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl`}>
        <div
          className={` ${hasBackgroundImages ? (hasMulticolor ? "" : "mb-16") : "mb-10"} lg:my-40 lg:max-w-lg lg:pr-5`}>
          <div className="inline-block px-3 py-px mb-4 text-xs  font-semibold tracking-wider uppercase rounded-full">
            <Editable html={surTitle.html} size={surTitle.size} editable={editable} />
          </div>
          <h2 className="mb-5 tracking-tight">
            <Editable html={title.html} size={title.size} editable={editable} />
          </h2>
          <div className="pr-5 mb-5">
            <Editable html={subTitle.html} size={subTitle.size} editable={editable} />
          </div>
          {/* {button.variant ? button.variant : "default"} */}
          <div className="flex items-center">
            {buttons.map((button, index) => (
              <Link key={index} href="/menu" passHref>
                <Button
                  variant="outline"
                  className="inline-flex items-center justify-center h-12 px-6 mr-6">
                  {button.html}
                </Button>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Block;
