"use client";
import { useEffect, useState } from "react";
import wait from "waait";
import ImageCrossFade from "./ImageCrossFade";

export default function BackgroundImage({ dark }: { dark: boolean }) {
  const [image, setImage] = useState<number>(0);

  const images = [
    "https://images.unsplash.com/photo-1533777857889-4be7c70b33f7?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1517248135467-4c7edcad34c4?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1564936281659-ba4f75a41956?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  ];

  useEffect(() => {
    wait(6000).then(() => {
      setImage((image + 1) % 3);
    });
  }, [image]);

  return <ImageCrossFade imgUrl={images[image]} width={600} height={800} dark={dark} />;
}
