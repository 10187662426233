import Image from "next/image";
import React, { useEffect, useState } from "react";
import wait from "waait";

interface ImageCrossFadeProps {
  imgUrl: string;
  width: number;
  height: number;
  dark: boolean;
}

const ImageCrossFade = ({ imgUrl, width, height, dark }: ImageCrossFadeProps) => {
  const [fadeIn, setFadeIn] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [loadedImage, setLoadedImage] = useState<string>(imgUrl);

  useEffect(() => {
    setLoaded(false);
    setFadeIn(true);
  }, [imgUrl]);

  return (
    <div className="absolute top-0  left-[-25px] bg-black w-[150%]">
      {fadeIn && (
        <Image
          onLoadingComplete={() => {
            setLoaded(true);
            wait(1100).then(() => {
              setLoadedImage(imgUrl);
            });
          }}
          alt=""
          src={imgUrl}
          priority
          width={width}
          height={height}
          className={`w-[150%] absolute top-0  left-[-25px] z-10 transition-opacity duration-1000 ${
            loaded ? "opacity-100" : "opacity-0"
          }`}
        />
      )}
      {loadedImage && (
        <Image
          onLoadingComplete={() => {
            setFadeIn(false);
          }}
          alt=""
          src={loadedImage}
          priority
          width={width}
          height={height}
          className={`w-[150%] absolute top-0  left-[-25px]`}
        />
      )}
      <div
        className="absolute left-[-25px] w-[150%] top-0 h-[440px] z-10"
        style={{
          background: dark
            ? "linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0.7) 20%)"
            : "linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0.8) 20%)",
        }}></div>
    </div>
  );
};

export default React.memo(ImageCrossFade);
