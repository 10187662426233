"use client";
import Image from "next/image";
import MultiCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  all: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
  },
};

export default function Carousel() {
  return (
    <div>
      <MultiCarousel responsive={responsive} ssr autoPlay infinite autoPlaySpeed={5000}>
        <div className="h-[300px] md:h-[400px] lg:h-[700px]">
          <Image
            alt="Image"
            className="object-cover"
            fill
            src="https://images.unsplash.com/photo-1681504837257-0e1295b075af?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8ODB8fHBpenplcmlhfGVufDB8fDB8fHww"
          />
        </div>
        <div className="h-[300px] md:h-[400px] lg:h-[600px]">
          <Image
            alt="Image"
            className="object-cover"
            fill
            src="https://images.unsplash.com/photo-1517248135467-4c7edcad34c4?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          />
        </div>
        <div className="h-[300px] md:h-[400px] lg:h-[600px]">
          <Image
            alt="Image"
            className="object-cover"
            fill
            src="https://images.unsplash.com/photo-1615897431041-5511a4e2e559?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTY2fHxwaXp6ZXJpYXxlbnwwfHwwfHx8MA%3D%3D"
          />
        </div>
        <div className="h-[300px] md:h-[400px] lg:h-[600px]">
          <Image
            alt="Image"
            className="object-cover"
            fill
            src="https://images.unsplash.com/photo-1533777857889-4be7c70b33f7?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          />
        </div>
      </MultiCarousel>
    </div>
  );
}
