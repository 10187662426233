import { cn } from "@/lib/utils";
import React from "react";
import ContentEditableForm from "./content-editable-form";
import { getDefaultTailwindClass, getTailwindClass } from "./size-helper";

interface TextEditableProps {
  html: string;
  size?: number;
  editable: boolean;
}
function Editable({
  html,
  size = getDefaultTailwindClass(),
  editable,
  className,
  ...props
}: TextEditableProps & React.HTMLAttributes<HTMLDivElement>): React.ReactElement {
  if (editable)
    return <ContentEditableForm html={html} size={size} className={className} {...props} />;
  else
    return (
      <div
        className={cn(getTailwindClass(size), className)}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    );
}

export default Editable;
