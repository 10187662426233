"use client";
import { cn } from "@/lib/utils";
import { Button } from "@lifizy/ui";
import React, { useState } from "react";
import ContentEditable from "react-contenteditable";
import { useEditUiContext } from "../../providers/edit/edit-ui-provider";
import { getTailwindClass } from "./size-helper";

interface ContentEditableFormProps {
  html: string;
  size?: number;
}
function ContentEditableForm({
  html,
  size,
  ...props
}: ContentEditableFormProps & React.HTMLAttributes<HTMLDivElement>): React.ReactElement {
  const [fontSize, setFontSize] = useState(size || 2);
  const [isFocus, setIsFocus] = useState(false);
  const editUi = useEditUiContext();

  const handleChange = (): void => {
    // Remove console.log or replace it with actual logic
  };
  const onBlur = (): void => {
    setIsFocus(false);
  };
  const onFocus = (): void => {
    setIsFocus(true);
  };
  const onChangeFontSize = (value: number): void => {
    if (value < 0 && fontSize === 0) return;
    if (value > 0 && fontSize === 11) return;
    setFontSize((prevFontSize) => prevFontSize + value);
  };
  return (
    <div className="relative">
      <div className={cn("absolute left-0 top-[-50px]", { hidden: !isFocus })}>
        <Button
          variant="app"
          onClick={() => {
            onChangeFontSize(-1);
          }}
          onMouseDown={(e) => {
            e.preventDefault();
          }}>
          -
        </Button>
        <Button
          variant="app"
          onClick={() => {
            onChangeFontSize(+1);
          }}
          onMouseDown={(e) => {
            e.preventDefault();
          }}>
          +
        </Button>
      </div>
      <div
        className={cn(getTailwindClass(fontSize), "focus:outline-slate-700", {
          "hover:outline-dashed": !isFocus && editUi.textEditable,
        })}>
        <ContentEditable
          disabled={!editUi.textEditable}
          html={html}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
          {...props}
        />
      </div>
    </div>
  );
}

export default ContentEditableForm;
